import Vue from 'vue/dist/vue.esm.js';
import VueResource from 'vue-resource';
import App from './App';
import router from './router';

import store from './components/store/store'

const appDOM = document.getElementById('app');

Vue.use(VueResource);

Vue.directive('title', {
	inserted: (el, binding) => document.title = binding.value,
	update: (el, binding) => document.title = binding.value
});

Vue.filter('capitalize', function (value) {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
});

new Vue({
	el: '#app',
	data() {
		return {
			//
		};
	},
	template: '<App/>',
	// declare components that the root component can access
	components: { App },
	// pass in the router to the Vue instance
	router,
	store
}).$mount(appDOM);

import Vue from 'vue/dist/vue.esm.js';
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		user: {
			loggedInStatus: false,
			uid: '',
			payment: false
		},
		info: {
			success: '',
			danger: '',
		},
		studentGlance: {
			studentIds: [],
			title: 'Total Student Hours',
			viewingAllStudents: true,
			yearGlance: false,
			diffStudentClick: false,
		},
		classroomInfo: {
			studentName: '',
			expDate: '',
			progress: '',
			points: 0,
			totalTries: 0,
			lessonCompletion: 0,
			currentLesson: '',
			matchingGameFinished: false,
			thumbsGameFinished: false,
			beatTheClockGameFinished: false,
			seqWithPicsGameFinished: false,
			seqWithTilesGameFinished: false,
			stopLightGameFinished: false,
			photoCardGameFinished: false,
			// matchingGameFinished: false,
			// matchingGameFinished: false,
			lesson1: ["nut", "map"],
			lesson2: ["fish", "vase", "nut", "map"],
			lesson3: ["thumb", "fish", "vase", "nut", "map"],
			lesson4: ["kite", "goat", "fish", "thumb", "fish", "vase", "nut", "map"],
			lesson5: ["sun", "ship", "kite", "goat", "fish", "thumb", "fish", "vase", "nut", "map"],
			lesson6: ["itchy", "eddy", "sun", "ship", "kite", "goat", "fish", "thumb", "fish", "vase", "nut", "map"],
			lesson7: ["upper", "itchy", "eddy", "sun", "ship", "kite", "goat", "fish", "thumb", "fish", "vase", "nut", "map"],
			lesson8: ["olive", "apple", "upper", "itchy", "eddy", "sun", "ship", "kite", "goat", "fish", "thumb", "fish", "vase", "nut", "map"],
			lesson9: ["lamp", "rat", "olive", "apple", "upper", "itchy", "eddy", "sun", "ship", "kite", "goat", "fish", "thumb", "fish", "vase", "nut", "map"],
			lesson10: ["duck", "ten", "lamp", "rat", "olive", "apple", "upper", "itchy", "eddy", "sun", "ship", "kite", "goat", "fish", "thumb", "fish", "vase", "nut", "map"],
			lesson11: ["chop", "jam", "duck", "ten", "lamp", "rat", "olive", "apple", "upper", "itchy", "eddy", "sun", "ship", "kite", "goat", "fish", "thumb", "fish", "vase", "nut", "map"],
			lesson12: ["pig", "balloon", "chop", "jam", "duck", "ten", "lamp", "rat", "olive", "apple", "upper", "itchy", "eddy", "sun", "ship", "kite", "goat", "fish", "thumb", "fish", "vase", "nut", "map"],
			WhiteListedTiles: [],
			studentID: null,
		}
	},
	mutations: {//mods
		SET_AUTH_STATUS: (state, status) => {
			state.user.loggedInStatus = status;
		},
		SET_USER_ID: (state, status) => {
			state.user.uid = status;
		},
		SET_USER_PAYMENT: (state, status) => {
			state.user.payment = status;
		},
		SET_INFO_SUCCESS: (state, info) => {
			state.info.success = info;
		},
		SET_INFO_DANGER: (state, info) => {
			state.info.danger = info;
		},
		SET_STUDENT_GLANCE_STUDENT_IDS: (state, ids) => {
			state.studentGlance.studentIds = ids;
		},
		SET_STUDENT_GLANCE_TITLE: (state, title) => {
			state.studentGlance.title = title;
		},
		SET__STUDENT_GLANCE_VIEWING_ALL: (state, bool) => {
			state.studentGlance.viewingAllStudents = bool;
		},
		SET__STUDENT_GLANCE_DIFF_STUDENT_CLICK: (state, bool) => {
			state.studentGlance.diffStudentClick = bool;
		},
		SET__STUDENT_GLANCE_YEAR_GLANCE: (state, bool) => {
			state.studentGlance.yearGlance = bool;
		},
		SET__CLASSROOM_STUDENT_NAME: (state, bool) => {
			state.classroomInfo.studentName = bool;
		},
		SET__CLASSROOM_EXP_DATE: (state, bool) => {
			state.classroomInfo.expDate = bool;
		},
		SET__CLASSROOM_PROGRESS: (state, bool) => {
			state.classroomInfo.progress = bool;
		},
		SET__CLASSROOM_POINTS: (state, int) => {
			state.classroomInfo.points = int;
		},
		SET__CLASSROOM_STUDENT_ID: (state, int) => {
			state.classroomInfo.studentID = int;
		},
		SET__CLASSROOM_TOTAL_TRIES: (state, int) => {
			state.classroomInfo.totalTries = int;
		},
		SET__CLASSROOM_LESSON_COMP: (state, int) => {
			state.classroomInfo.lessonCompletion = int;
		},
		SET__CLASSROOM_CURRENT_LESSON: (state, info) => {
			state.classroomInfo.currentLesson = info;
		},
		SET__CLASSROOM_WHITE_LISTED_TILES: (state, info) => {
			state.classroomInfo.WhiteListedTiles = info;
		},

		SET__CLASSROOM_MATCHING_GAME_FINISHED: (state, info) => {
			state.classroomInfo.matchingGameFinished = info;
		},
		SET__CLASSROOM_THUMBS_GAME_FINISHED: (state, info) => {
			state.classroomInfo.thumbsGameFinished = info;
		},
		SET__CLASSROOM_BEAT_THE_CLOCK_FINISHED: (state, info) => {
			state.classroomInfo.beatTheClockGameFinished = info;
		},
		SET__CLASSROOM_SEQ_PICS_FINISHED: (state, info) => {
			state.classroomInfo.seqWithPicsGameFinished = info;
		},
		SET__CLASSROOM_SEQ_TILES_FINISHED: (state, info) => {
			state.classroomInfo.seqWithTilesGameFinished = info;
		},
		SET__CLASSROOM_STOP_LIGHT_FINISHED: (state, info) => {
			state.classroomInfo.stopLightGameFinished = info;
		},
		SET__CLASSROOM_PHOTO_CARD_FINISHED: (state, info) => {
			state.classroomInfo.photoCardGameFinished = info;
		},
	},
	getters: {//gets
		getLoggedInStatus: function (state) {
			return state.user.loggedInStatus;
		},
		getUserID: function (state) {
			return state.user.uid;
		},
		getUserPayment: function (state) {
			return state.user.payment;
		},
		getInfoSuccess: function (state) {
			return state.info.success;
		},
		getInfoDanger: function (state) {
			return state.info.danger;
		},
		getStudentGlanceStudentIds: function (state) {
			return state.studentGlance.studentIds;
		},
		getStudentGlanceTitle: function (state) {
			return state.studentGlance.title;
		},
		getStudentGlanceViewingAllStudents: function (state) {
			return state.studentGlance.viewingAllStudents;
		},
		getStudentGlanceDiffStudentClick: function (state) {
			return state.studentGlance.diffStudentClick;
		},
		getStudentGlanceYearGlance: function (state) {
			return state.studentGlance.yearGlance;
		},
		getClassroomInfoStudentName: function (state) {
			return state.classroomInfo.studentName;
		},
		getClassroomInfoExpDate: function (state) {
			return state.classroomInfo.expDate;
		},
		getClassroomInfoProgress: function (state) {
			return state.classroomInfo.progress;
		},
		getClassroomInfoPoints: function (state) {
			return state.classroomInfo.points;
		},
		getClassroomInfoStudentID: function (state) {
			return state.classroomInfo.studentID;
		},
		getClassroomInfoTotalTries: function (state) {
			return state.classroomInfo.totalTries;
		},
		getClassroomInfoLessonCompletion: function (state) {
			return state.classroomInfo.lessonCompletion;
		},
		getClassroomInfoCurrentLesson: function (state) {
			return state.classroomInfo.currentLesson;
		},

		getClassroomInfoLesson1: function (state) {
			return state.classroomInfo.lesson1;
		},
		getClassroomInfoLesson2: function (state) {
			return state.classroomInfo.lesson2;
		},
		getClassroomInfoLesson3: function (state) {
			return state.classroomInfo.lesson3;
		},
		getClassroomInfoLesson4: function (state) {
			return state.classroomInfo.lesson4;
		},
		getClassroomInfoLesson5: function (state) {
			return state.classroomInfo.lesson5;
		},
		getClassroomInfoLesson6: function (state) {
			return state.classroomInfo.lesson6;
		},
		getClassroomInfoLesson7: function (state) {
			return state.classroomInfo.lesson7;
		},
		getClassroomInfoLesson8: function (state) {
			return state.classroomInfo.lesson8;
		},
		getClassroomInfoLesson9: function (state) {
			return state.classroomInfo.lesson9;
		},
		getClassroomInfoLesson10: function (state) {
			return state.classroomInfo.lesson10;
		},
		getClassroomInfoLesson11: function (state) {
			return state.classroomInfo.lesson11;
		},
		getClassroomInfoLesson12: function (state) {
			return state.classroomInfo.lesson12;
		},
		getClassroomInfoWhiteListedTiles: function (state) {
			return state.classroomInfo.WhiteListedTiles;
		},

		getClassroomInfoMatchingGameFinished: function (state) {
			return state.classroomInfo.matchingGameFinished;
		},
		getClassroomInfoThumbsGameFinished: function (state) {
			return state.classroomInfo.thumbsGameFinished;
		},
		getClassroomInfoBeatTheClockGameFinished: function (state) {
			return state.classroomInfo.beatTheClockGameFinished;
		},
		getClassroomInfoSeqWithPicsGameFinished: function (state) {
			return state.classroomInfo.seqWithPicsGameFinished;
		},
		getClassroomInfoSeqWithTilesGameFinished: function (state) {
			return state.classroomInfo.seqWithTilesGameFinished;
		},
		getClassroomInfoStopLightGameFinished: function (state) {
			return state.classroomInfo.stopLightGameFinished;
		},
		getClassroomInfoPhotoCardGameFinished: function (state) {
			return state.classroomInfo.photoCardGameFinished;
		},



	},
	actions: {//calls mods
		setAuthStatus: (context, status) => {
			context.commit("SET_AUTH_STATUS", status);
		},
		setUserId: (context, id) => {
			context.commit("SET_USER_ID", id);
		},
		setUserPayment: (context, payment) => {
			context.commit("SET_USER_PAYMENT", payment);
		},
		setInfoSuccess: (context, info) => {
			context.commit("SET_INFO_SUCCESS", info);
		},
		setInfoDanger: (context, info) => {
			context.commit("SET_INFO_DANGER", info);
		},
		setStudentGlanceStudentIds: (context, ids) => {
			context.commit("SET_STUDENT_GLANCE_STUDENT_IDS", ids);
		},
		setStudentGlanceTitle: (context, title) => {
			context.commit("SET_STUDENT_GLANCE_TITLE", title);
		},
		setStudentGlanceViewingAllStudents: (context, bool) => {
			context.commit("SET__STUDENT_GLANCE_VIEWING_ALL", bool);
		},
		setStudentGlanceDiffStudentClick: (context, bool) => {
			context.commit("SET__STUDENT_GLANCE_DIFF_STUDENT_CLICK", bool);
		},
		setStudentGlanceYearGlance: (context, bool) => {
			context.commit("SET__STUDENT_GLANCE_YEAR_GLANCE", bool);
		},
		setClassroomInfoStudentName: (context, info) => {
			context.commit("SET__CLASSROOM_STUDENT_NAME", info);
		},
		setClassroomInfoExpDate: (context, info) => {
			context.commit("SET__CLASSROOM_EXP_DATE", info);
		},
		setClassroomInfoProgress: (context, info) => {
			context.commit("SET__CLASSROOM_PROGRESS", info);
		},
		setClassroomInfoPoints: (context, int) => {
			context.commit("SET__CLASSROOM_POINTS", int);
		},
		setClassroomInfoStudentID: (context, int) => {
			context.commit("SET__CLASSROOM_STUDENT_ID", int);
		},
		setClassroomInfoTotalTries: (context, int) => {
			context.commit("SET__CLASSROOM_TOTAL_TRIES", int);
		},
		setClassroomInfoLessonCompletion: (context, int) => {
			context.commit("SET__CLASSROOM_LESSON_COMP", int);
		},
		setClassroomInfoCurrentLesson: (context, info) => {
			context.commit("SET__CLASSROOM_CURRENT_LESSON", info);
		},
		setClassroomInfoWhiteListedTiles: (context, info) => {
			context.commit("SET__CLASSROOM_WHITE_LISTED_TILES", info);
		},

		setClassroomInfoMatchingGameFinished: (context, info) => {
			context.commit("SET__CLASSROOM_MATCHING_GAME_FINISHED", info);
		},
		setClassroomInfoThumbsGameFinished: (context, info) => {
			context.commit("SET__CLASSROOM_THUMBS_GAME_FINISHED", info);
		},
		setClassroomInfoBeatTheClockFinished: (context, info) => {
			context.commit("SET__CLASSROOM_BEAT_THE_CLOCK_FINISHED", info);
		},
		setClassroomInfoSeqPicsFinished: (context, info) => {
			context.commit("SET__CLASSROOM_SEQ_PICS_FINISHED", info);
		},
		setClassroomInfoSeqTilesFinished: (context, info) => {
			context.commit("SET__CLASSROOM_SEQ_TILES_FINISHED", info);
		},
		setClassroomInfoStopLightFinished: (context, info) => {
			context.commit("SET__CLASSROOM_STOP_LIGHT_FINISHED", info);
		},
		setClassroomInfoPhotoCardFinished: (context, info) => {
			context.commit("SET__CLASSROOM_PHOTO_CARD_FINISHED", info);
		},
	},
	plugins: [createPersistedState()],
});

export default store;

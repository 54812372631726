<template>
  <div id="home" style="margin-top: 15px;">
    <a
      @click="showSettings = !showSettings"
      href="#"
      v-show="false === showPayment"
      id="settingsBtn"
      style="
    margin-top: -76px;
    position: absolute;"
    >
      <i class="medium material-icons">settings</i>
    </a>
    <div v-show="'thinking' === showPayment">Loading...</div>
    <div id="checkout" class="container" v-show="true === showPayment">
      <div class="card-panel">
        <h5 class="center">Single Tutor Subscription</h5>
        <h4 class="center bold cyan-text text-lighten-1">
          $37
          <small class="black-text">/ 30 Days</small>
        </h4>
      </div>
      <!-- Use the CSS tab above to style your Element's container. -->
      <form id="subscription-form">
        <div id="card-element" class="MyCardElement">
          <!-- Elements will create input elements here -->
        </div>

        <!-- We'll put the error messages in this element -->
        <div id="card-errors" role="alert"></div>
        <br />
        <button
          type="submit"
          id="cardSubmit"
          class="btn waves-effect waves-light cyan lighten-1"
        >Subscribe</button>
      </form>
    </div>
    <div v-show="showSettings" id="settings" class="card-panel cyan lighten-5">
      <h5>Settings</h5>
      <div class="row">
        <div class="col s6">
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Map.jpg?alt=media&token=7eb1d8ec-074f-4230-85fd-2a37ed3a6225"
            />

            <input type="checkbox" v-model="map" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Nut.jpg?alt=media&token=0ce40e24-1ab5-4720-a3d7-2da28bf232c6"
            />

            <input type="checkbox" v-model="nut" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Fish.jpg?alt=media&token=ca46a18f-1aea-40f5-9dd9-3ed41f79788c"
            />

            <input type="checkbox" v-model="fish" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Vase.jpg?alt=media&token=7384c712-c274-40fa-be93-bc00fba8ad4e"
            />

            <input type="checkbox" v-model="vase" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Thumb.jpg?alt=media&token=c0f0d7d0-9a69-4261-a805-9f05a36364a8"
            />

            <input type="checkbox" v-model="thumb" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Kite.jpg?alt=media&token=7d0530ff-36a7-4c12-90d9-b6985722742d"
            />

            <input type="checkbox" v-model="kite" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Goat.jpg?alt=media&token=aa4068bc-5665-4a8d-b215-661eb8c2bbb9"
            />

            <input type="checkbox" v-model="goat" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Sun.jpg?alt=media&token=f22d1145-1b97-4e97-995c-2016e3d90835"
            />

            <input type="checkbox" v-model="sun" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Ship.jpg?alt=media&token=30a5ae50-b1ea-4d5d-88b3-a02f036b31bc"
            />

            <input type="checkbox" v-model="ship" />
            <span></span>
          </label>

          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Itchy.jpg?alt=media&token=2ba34206-41f1-4448-ada2-716f859c3e20"
            />

            <input type="checkbox" v-model="itchy" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Eddy.jpg?alt=media&token=22a10f6d-582d-4c24-afd2-d2296a314ed0"
            />

            <input type="checkbox" v-model="eddy" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Upper.jpg?alt=media&token=733717f3-a813-463b-91cd-591d6216a23f"
            />

            <input type="checkbox" v-model="upper" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Apple.jpg?alt=media&token=2c5c9fb2-e254-4180-8740-61102e340bd9"
            />
            <input type="checkbox" v-model="apple" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Olive.jpg?alt=media&token=51545543-4110-489d-9d79-c0188bddcf1e"
            />

            <input type="checkbox" v-model="olive" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Lamp.jpg?alt=media&token=621f4c64-2e73-4296-b294-79a3038a3adf"
            />

            <input type="checkbox" v-model="lamp" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Rat.jpg?alt=media&token=378aec7a-9b76-4f0c-9920-07f515d1c95c"
            />

            <input type="checkbox" v-model="rat" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Duck.jpg?alt=media&token=9788f1d1-d520-4152-b9f6-e9bff8b2a55d"
            />

            <input type="checkbox" v-model="duck" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Ten.jpg?alt=media&token=a8a79c41-8b3f-4efd-82ce-4365bc4d0e8a"
            />

            <input type="checkbox" v-model="ten" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Chop.jpg?alt=media&token=ce173fa4-9c3d-42bb-aaa7-c78f0c64b513"
            />

            <input type="checkbox" v-model="chop" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Jam.jpg?alt=media&token=5cec508c-34c8-43f3-8c0e-7fd59758d1d4"
            />

            <input type="checkbox" v-model="jam" />
            <span></span>
          </label>

          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Balloon.jpg?alt=media&token=be43ff65-3fad-496b-9c28-91fdd187c92c"
            />

            <input type="checkbox" v-model="balloon" />
            <span></span>
          </label>

          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Pig.jpg?alt=media&token=d051f198-5346-4817-99c8-83627b3f3d8f"
            />

            <input type="checkbox" v-model="pig" />
            <span></span>
          </label>
        </div>
        <div class="col s6">
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Sounds%20Stoplight.jpg?alt=media&token=766a8583-1d27-4e55-aa0c-032464b9306f"
            />

            <input name="group1" type="radio" value="stopLight" v-model="selectedSheet" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Sequencing%20with%20Colored%20Tiles%20Activity%20Sheet%20-%20With%20Three%20Lines.jpg?alt=media&token=6aa5d123-affc-4e53-8b01-8e408bca20c2"
            />

            <input name="group1" type="radio" value="color3" v-model="selectedSheet" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Sequencing%20with%20Colored%20Tiles%20Activity%20Sheet%20-%20With%20Two%20Lines.jpg?alt=media&token=bd0cbf93-f8c3-4a66-9984-45c4342a88b7"
            />

            <input name="group1" type="radio" value="color2" v-model="selectedSheet" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Sequencing%20with%20Sound%20Pictures%20Activity%20Sheet%20-%20With%20Three%20Lines.jpg?alt=media&token=602b4826-b0c7-46f7-a1bf-8f2c0b64b391"
            />

            <input name="group1" type="radio" value="sound3" v-model="selectedSheet" />
            <span></span>
          </label>
          <label>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Sequencing%20with%20Sound%20Pictures%20Activity%20Sheet%20-%20With%20Two%20Lines.jpg?alt=media&token=746a2612-9f23-494d-9c8e-8895a3bd889c"
            />

            <input name="group1" type="radio" value="sound2" v-model="selectedSheet" />
            <span></span>
          </label>
        </div>
      </div>
    </div>
    <div v-show="false === showPayment && false === showSettings">
      <div class="row">
        <div class="col s4">
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="map"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Map.jpg?alt=media&token=7eb1d8ec-074f-4230-85fd-2a37ed3a6225"
            />
            <img
              class="draggable"
              v-show="map"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Map.jpg?alt=media&token=7eb1d8ec-074f-4230-85fd-2a37ed3a6225"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="nut"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Nut.jpg?alt=media&token=0ce40e24-1ab5-4720-a3d7-2da28bf232c6"
            />
            <img
              class="draggable"
              v-show="nut"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Nut.jpg?alt=media&token=0ce40e24-1ab5-4720-a3d7-2da28bf232c6"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="fish"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Fish.jpg?alt=media&token=ca46a18f-1aea-40f5-9dd9-3ed41f79788c"
            />
            <img
              class="draggable"
              v-show="fish"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Fish.jpg?alt=media&token=ca46a18f-1aea-40f5-9dd9-3ed41f79788c"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="vase"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Vase.jpg?alt=media&token=7384c712-c274-40fa-be93-bc00fba8ad4e"
            />
            <img
              class="draggable"
              v-show="vase"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Vase.jpg?alt=media&token=7384c712-c274-40fa-be93-bc00fba8ad4e"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="thumb"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Thumb.jpg?alt=media&token=c0f0d7d0-9a69-4261-a805-9f05a36364a8"
            />
            <img
              class="draggable"
              v-show="thumb"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Thumb.jpg?alt=media&token=c0f0d7d0-9a69-4261-a805-9f05a36364a8"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="kite"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Kite.jpg?alt=media&token=7d0530ff-36a7-4c12-90d9-b6985722742d"
            />
            <img
              class="draggable"
              v-show="kite"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Kite.jpg?alt=media&token=7d0530ff-36a7-4c12-90d9-b6985722742d"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="goat"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Goat.jpg?alt=media&token=aa4068bc-5665-4a8d-b215-661eb8c2bbb9"
            />
            <img
              class="draggable"
              v-show="goat"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Goat.jpg?alt=media&token=aa4068bc-5665-4a8d-b215-661eb8c2bbb9"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="sun"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Sun.jpg?alt=media&token=f22d1145-1b97-4e97-995c-2016e3d90835"
            />
            <img
              class="draggable"
              v-show="sun"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Sun.jpg?alt=media&token=f22d1145-1b97-4e97-995c-2016e3d90835"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="ship"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Ship.jpg?alt=media&token=30a5ae50-b1ea-4d5d-88b3-a02f036b31bc"
            />
            <img
              class="draggable"
              v-show="ship"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Ship.jpg?alt=media&token=30a5ae50-b1ea-4d5d-88b3-a02f036b31bc"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="itchy"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Itchy.jpg?alt=media&token=2ba34206-41f1-4448-ada2-716f859c3e20"
            />
            <img
              class="draggable"
              v-show="itchy"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Itchy.jpg?alt=media&token=2ba34206-41f1-4448-ada2-716f859c3e20"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="eddy"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Eddy.jpg?alt=media&token=22a10f6d-582d-4c24-afd2-d2296a314ed0"
            />
            <img
              class="draggable"
              v-show="eddy"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Eddy.jpg?alt=media&token=22a10f6d-582d-4c24-afd2-d2296a314ed0"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="upper"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Upper.jpg?alt=media&token=733717f3-a813-463b-91cd-591d6216a23f"
            />
            <img
              class="draggable"
              v-show="upper"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Upper.jpg?alt=media&token=733717f3-a813-463b-91cd-591d6216a23f"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="apple"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Apple.jpg?alt=media&token=2c5c9fb2-e254-4180-8740-61102e340bd9"
            />
            <img
              class="draggable"
              v-show="apple"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Apple.jpg?alt=media&token=2c5c9fb2-e254-4180-8740-61102e340bd9"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="olive"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Olive.jpg?alt=media&token=51545543-4110-489d-9d79-c0188bddcf1e"
            />
            <img
              class="draggable"
              v-show="olive"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Olive.jpg?alt=media&token=51545543-4110-489d-9d79-c0188bddcf1e"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="lamp"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Lamp.jpg?alt=media&token=621f4c64-2e73-4296-b294-79a3038a3adf"
            />
            <img
              class="draggable"
              v-show="lamp"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Lamp.jpg?alt=media&token=621f4c64-2e73-4296-b294-79a3038a3adf"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="rat"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Rat.jpg?alt=media&token=378aec7a-9b76-4f0c-9920-07f515d1c95c"
            />
            <img
              class="draggable"
              v-show="rat"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Rat.jpg?alt=media&token=378aec7a-9b76-4f0c-9920-07f515d1c95c"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="duck"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Duck.jpg?alt=media&token=9788f1d1-d520-4152-b9f6-e9bff8b2a55d"
            />
            <img
              class="draggable"
              v-show="duck"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Duck.jpg?alt=media&token=9788f1d1-d520-4152-b9f6-e9bff8b2a55d"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="ten"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Ten.jpg?alt=media&token=a8a79c41-8b3f-4efd-82ce-4365bc4d0e8a"
            />
            <img
              class="draggable"
              v-show="ten"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Ten.jpg?alt=media&token=a8a79c41-8b3f-4efd-82ce-4365bc4d0e8a"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="chop"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Chop.jpg?alt=media&token=ce173fa4-9c3d-42bb-aaa7-c78f0c64b513"
            />
            <img
              class="draggable"
              v-show="chop"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Chop.jpg?alt=media&token=ce173fa4-9c3d-42bb-aaa7-c78f0c64b513"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="jam"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Jam.jpg?alt=media&token=5cec508c-34c8-43f3-8c0e-7fd59758d1d4"
            />
            <img
              class="draggable"
              v-show="jam"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Jam.jpg?alt=media&token=5cec508c-34c8-43f3-8c0e-7fd59758d1d4"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="balloon"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Balloon.jpg?alt=media&token=be43ff65-3fad-496b-9c28-91fdd187c92c"
            />
            <img
              class="draggable"
              v-show="balloon"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Balloon.jpg?alt=media&token=be43ff65-3fad-496b-9c28-91fdd187c92c"
            />
          </div>
          <div class="imgContainer">
            <img
              class="draggable"
              v-show="pig"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Pig.jpg?alt=media&token=d051f198-5346-4817-99c8-83627b3f3d8f"
            />
            <img
              class="draggable"
              v-show="pig"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Pig.jpg?alt=media&token=d051f198-5346-4817-99c8-83627b3f3d8f"
            />
          </div>
        </div>
        <div class="col s1">
          <div
            id="colors"
            style=" display: flex;justify-content: center; position: absolute;display: grid;"
          >
            <div class="over">
              <div class="dot draggable" style="background-color: yellow;"></div>
              <div class="dot draggable" style="background-color: yellow;"></div>
            </div>
            <div class="over">
              <div class="dot draggable" style="background-color: blue;"></div>
              <div class="dot draggable" style="background-color: blue;"></div>
            </div>
            <div class="over">
              <div class="dot draggable" style="background-color: green;"></div>
              <div class="dot draggable" style="background-color: green;"></div>
            </div>
            <div class="over">
              <div class="dot draggable" style="background-color: orange;"></div>
              <div class="dot draggable" style="background-color: orange;"></div>
            </div>
            <div class="over">
              <div class="dot draggable" style="background-color: red;"></div>
              <div class="dot draggable" style="background-color: red;"></div>
            </div>
          </div>
        </div>
        <div class="col s7" id="mainBGs">
          <div class="row">
            <img
              style="height: 40vh;"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Lip%20Chart%20for%20Quiet%20Sounds.jpg?alt=media&token=6691fcb4-efc2-46b9-9fe2-5767f48cef5e"
              class="col s4"
            />
            <img
              class="col s4"
              style="height: 40vh;"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Vowel%20Chart.jpg?alt=media&token=b62f4b5a-a98b-4861-9128-14f00e3b9d1e"
            />

            <img
              style="height: 40vh;"
              class="col s4"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Tongue%20Chart%20for%20Quiet%20Sounds.jpg?alt=media&token=05d871b6-418f-4fca-843b-1bed9ce91a00"
            />
          </div>

          <div class="row">
            <!-- <div class="col s12"> -->
            <img
              style="height: 40vh;"
              class="col s4"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Lip%20Chart%20for%20Vibrating%20Sounds.jpg?alt=media&token=7550bb43-b36b-4c8d-bca0-e509c05980f8"
            />
            <img
              style="height: 40vh;"
              class="col s4"
              v-show="'stopLight' === selectedSheet"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Sounds%20Stoplight.jpg?alt=media&token=766a8583-1d27-4e55-aa0c-032464b9306f"
            />
            <img
              style="height: 40vh;"
              class="col s4"
              v-show="'color3' === selectedSheet"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Sequencing%20with%20Colored%20Tiles%20Activity%20Sheet%20-%20With%20Three%20Lines.jpg?alt=media&token=6aa5d123-affc-4e53-8b01-8e408bca20c2"
            />
            <img
              style="height: 40vh;"
              class="col s4"
              v-show="'color2' === selectedSheet"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Sequencing%20with%20Colored%20Tiles%20Activity%20Sheet%20-%20With%20Two%20Lines.jpg?alt=media&token=bd0cbf93-f8c3-4a66-9984-45c4342a88b7"
            />
            <img
              style="height: 40vh;"
              class="col s4"
              v-show="'sound3' === selectedSheet"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Sequencing%20with%20Sound%20Pictures%20Activity%20Sheet%20-%20With%20Three%20Lines.jpg?alt=media&token=602b4826-b0c7-46f7-a1bf-8f2c0b64b391"
            />
            <img
              style="height: 40vh;"
              class="col s4"
              v-show="'sound2' === selectedSheet"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Sequencing%20with%20Sound%20Pictures%20Activity%20Sheet%20-%20With%20Two%20Lines.jpg?alt=media&token=746a2612-9f23-494d-9c8e-8895a3bd889c"
            />
            <img
              style="height: 40vh;"
              class="col s4"
              src="https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/Tongue%20Chart%20for%20Vibrating%20Sounds.jpg?alt=media&token=8efd677e-5171-4c8b-9110-dcfe68c814c8"
            />
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

//import "core-js/stable";
import "regenerator-runtime/runtime";


export default {
  name: 'Home',
  data() {
    return {
      email: '',
      showPayment: 'thinking',
      selectedSheet: 'stopLight',
      showSettings: false,
      apple: true,
      balloon: true,
      chop: true,
      duck: true,
      eddy: true,
      fish: true,
      goat: true,
      itchy: true,
      jam: true,
      kite: true,
      lamp: true,
      map: true,
      nut: true,
      olive: true,
      pig: true,
      rat: true,
      ship: true,
      sun: true,
      ren: true,
      ten: true,
      thumb: true,
      upper: true,
      vase: true,
    }
  },
  async created() {
    const that = this;

  },
  async mounted() {
    const that = this;

    await firebase.auth().onAuthStateChanged(function (user) {
      return new Promise((resolve) => {
        if (user) {
          // User is signed in.
          that.email = user.email;
          resolve();
        } else {
          // No user is signed in.
        }
      });

    });

    fetch('/check-subscription', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: that.email,
      }),
    }).then(function (result) {
      return result.json();
    }).then(function (data) {

      if (true == data.success) {
        that.showPayment = false;
      } else {
        that.showPayment = true;
      }

    });

    var stripe = Stripe('pk_live_5JplEQ7Ez9qLhIvMWBDmHdSI');
    var elements = stripe.elements();

    // Set up Stripe.js and Elements to use in checkout form
    var style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };

    var card = elements.create("card", { style: style });
    card.mount("#card-element");

    card.addEventListener('change', function (event) {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });


    var form = document.getElementById('subscription-form');

    form.addEventListener('submit', function (event) {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
      document.getElementById("cardSubmit").disabled = true;

      stripe.createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          email: that.email,
        },
      }).then(stripePaymentMethodHandler);
    });

    function stripePaymentMethodHandler(result, email) {
      if (result.error) {
        // Show error in payment form
        document.getElementById("cardSubmit").disabled = false;
      } else {

        fetch('/create-customer', {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email: that.email,
            payment_method: result.paymentMethod.id
          }),
        }).then(function (result) {
          //document.getElementById("cardSubmit").disabled = false;
          return result.json();
        }).then(function (data) {
          if (true == data.success) {
            that.showPayment = false;
          } else {
            alert(data.message);
            that.showPayment = true;
          }
          document.getElementById("cardSubmit").disabled = false;

        });

      }

    }


    that.invokeDragAndDrop();


  },
  methods: {
    invokeDragAndDrop() {

      $('.draggable').draggable();

    }
  }
}
</script>

<style scoped>
a {
	color: #26c6da 
}

.over {
	float: left;
	margin: 25px;
}

.dot {
	height: 40px !important;
    width: 40px;
	position: absolute !important;
}

.draggable {
	height: 100px;
}

#mainBGs img {
	height: 100%;
}

.imgContainer {
    float: left;
    position: relative !important;
    margin-right: 82px;
    margin-bottom: 110px;
}

.imgContainer img {
position: absolute !important;
}


#shared img {
	height: 100%;
}

#settings img {
	height: 150px;;
}

#settings label {
	float: left;
}


.card-panel {
	    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
}

#settingsBtn i {
color: white !important;
}

/**
* Shows how you can use CSS to style your Element's container.
*/
.MyCardElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.MyCardElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.MyCardElement--invalid {
  border-color: #fa755a;
}

.MyCardElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>

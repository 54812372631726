<template>
  <div id="sign-in" class="container">
    <h3 class="center">Welcome</h3>
    <h5 class="center">Please Sign In / Sign Up</h5>
	<p class="center"><span id="cancelSubscription">*In order to end your subscription please email <a href="mailto:Support@FoundationInSounds.com">Support@FoundationInSounds.com</a>.</span></p>
    <div id="firebaseui-auth-container" style="margin-top: 50px;"></div>
    <div style=" display: flex;
  justify-content: center;">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/YXGpAfsyQ0Q"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>

export default {
  name: "signin",
  data() {
    return {

    }
  },
  mounted() {
    const that = this;

    // Initialize the FirebaseUI Widget using Firebase.
    var ui = new firebaseui.auth.AuthUI(firebase.auth());

    ui.start('#firebaseui-auth-container', {
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          requireDisplayName: true,
        },
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: true,
        }
      ],
      signInSuccessUrl: '/home',
      // Terms of service url.
      tosUrl: 'https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/tos.pdf?alt=media&token=f1ac1fda-c526-4588-97e2-9faa2ed3df6e',
      // Privacy policy url.
      privacyPolicyUrl: 'https://firebasestorage.googleapis.com/v0/b/simplefis.appspot.com/o/pp.pdf?alt=media&token=ec938a4b-12a5-48fd-b0b0-6016086ef94d'
    });

  },
  methods: {

  }

}
</script>

<style scoped>
#cancelSubscription {
	color: gray;
	font-size: 13px;
}

</style>


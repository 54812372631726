import Vue from 'vue/dist/vue.esm.js';
import VueRouter from 'vue-router'

import Signin from './components/Signin'

import Home from './components/Home'


import store from './components/store/store'

Vue.use(VueRouter);

const router = new VueRouter({
	routes: [
		{
			path: '/',
			component: Signin,
			meta: {
				title: 'Sign In'
			}
		}, {
			path: '/signin',
			component: Signin,
			meta: {
				title: 'Sign In'
			}
		}, {
			path: '/home',
			component: Home,
			meta: {
				requiresAuth: true,
				title: 'Home'
			}
		}
	],
	scrollBehavior: function (to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash
			}
		} else {
			return {
				x: 0, y: 0
			}
		}
	},
	mode: 'history'
});

router.beforeEach(function (to, from, next) {

	firebase.auth().onAuthStateChanged(function (user) {
		const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
		const toPath = to.path;

		if (requiresAuth && !user) {
			next('/signin');
		} else if (user && '/' === toPath) {
			next('/home');
		} else if (user && '/signin' === toPath) {
			next('/home');
		} else if (user && '/signup' === toPath) {
			next('/home');
		} else {
			next();
		}
	});

});

export default router;

<template>
  <div id="app">
    <nav class="cyan lighten-1">
      <div class="container nav-wrapper cyan lighten-1">
        <a href="#" class="brand-logo"></a>
        <ul id="nav-mobile" class="right hide-on-med-and-down">
          <li>
            <a @click="logOut" v-show="userIn" href="#">Logout</a>
          </li>
        </ul>
      </div>
    </nav>
    <!-- Views -->
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "app",
  data() {
    return {
      userIn: false,
    }
  },
  created() {
    const that = this;

    // firebase.auth().onAuthStateChanged(function (user) {
    //   if (user) {

    //   } else {

    //   }
    // });

    // const getUserRef = userId => firebase.database().ref(`/users/${userId}`);

    // const ensureUserSession = userId => {
    //   const userRef = getUserRef(userId);

    //   return userRef.once("value").then(snapshot => {
    //     if (!snapshot.exists()) {
    //       return userRef.set({
    //         last_seen: firebase.database.ServerValue.TIMESTAMP
    //       });
    //     }

    //     const user = snapshot.val();

    //     if (user.is_online) {
    //       alert("Opps, please logout of other exisiting sessions first.");
    //       return firebase.auth().signOut();
    //     }

    //     return Promise.resolve();
    //   });
    // };

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        that.userIn = true;
        // return ensureUserSession(user.uid)
        //   .then(() => {
        //     const userRef = getUserRef(user.uid);

        //     return userRef
        //       .onDisconnect()
        //       .set({
        //         is_online: false,
        //         last_seen: firebase.database.ServerValue.TIMESTAMP
        //       }).then(() => {
        //         userRef.set({
        //           is_online: true,
        //           last_seen: firebase.database.ServerValue.TIMESTAMP
        //         });
        //       });
        //   }).catch(err => {
        //     console.error(err);

        //     if (err instanceof SessionAlreadyExists) {
        //       alert("Opps, please logout of other exisiting sessions first.");
        //       return firebase.auth().signOut();
        //     }
        //   });
      } else {
        that.userIn = false;
      }
    });
  },
  mounted() {
    const that = this;

  },
  methods: {
    logOut: function () {
      const that = this;

      const userID = firebase.auth().currentUser.uid;

      const getUserRef = userId => firebase.database().ref(`/users/${userId}`);

      const userRef = getUserRef(userID);

      userRef.set({
        is_online: false,
        last_seen: firebase.database.ServerValue.TIMESTAMP
      }).then(ss => {

        firebase.auth().signOut().then(function () {
          // Sign-out successful.
          that.$store.dispatch("setAuthStatus", false);
          that.$store.dispatch("setUserId", '');
          that.$router.push('/');
        }).catch(function (error) {
          // An error happened.
        });
      }).catch(error => {
        console.error(error)
      });

    },
    endClassSession() {
      this.$root.$emit('endClassSession');
    },

  }
}
</script>

<style>


#kt_aside_footer {
      position: fixed;
    bottom: 0px;
    width: 260px;
}


</style>
